import React, { Component } from "react";
import Slider from "react-slick";
import { ReactComponent as AgDakar } from "../../images/clients/agdakar.svg";
import { ReactComponent as Heficed } from "../../images/clients/heficed.svg";
import { ReactComponent as Intellectus } from "../../images/clients/intellectus.svg";
import { ReactComponent as Mttc } from "../../images/clients/mttc.svg";
import { ReactComponent as RawPowders } from "../../images/clients/rawpowders.svg";
import { ReactComponent as SoloSociety } from "../../images/clients/solosociety.svg";

class ClientsSection extends Component {
  render() {
    const settings = {
      slidesToShow: 6,
      slidesToScroll: 1,
      variableWidth: true,
      className: "clients-logo-slider",
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            variableWidth: false,
            arrows: false,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            variableWidth: false,
            arrows: false,
            infinite: true,
            dots: true
          }
        }
      ]
    };
    return (
      <div id="clients-section">
        <div className="clients-section__wrapper">
          <h4 className="clients-section__text">
            Collaborations based on trust
          </h4>
          <div className="clients-section__logos">
            <Slider {...settings}>
              <AgDakar />
              <SoloSociety />
              <Heficed />
              <Mttc />
              <Intellectus />
              <RawPowders />
            </Slider>
          </div>
        </div>
        <p className="coming-soon">
          More content coming soon
          <span id="wave">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
          </span>
        </p>
      </div>
    );
  }
}

export default ClientsSection;
