import React, { Component } from "react";
import { ReactComponent as Logo } from "../../images/logo.svg";

class HeroSection extends Component {
  render() {
    return (
      <div className="hero-section">
        <div className="hero-section__header">
          <div className="hero-section__header-logo-wrapper">
            <Logo />
          </div>
          <div className="hero-section__header-contacts-wrapper">
            <p className="hero-section__header-message">
              Your questions and special requests are always welcome.
              <a
                className="hero-section__header-contacts-email"
                href="mailto:hello@liger.lt"
              >
                hello@liger.lt
              </a>
            </p>
          </div>
        </div>
        <div className="hero-section__content">
          <h1 className="hero-section__main-message">
            <span className="hero-section__main-message--top-line">
              Creative Duo of
            </span>
            <span className="hero-section__main-message--bottom-line">
              Digital Jungle
            </span>
          </h1>
        </div>
        <div className="hero-section__footer">
          <h3 className="hero-section__footer-content">
            The balance between technology, design and strategy
          </h3>
        </div>
      </div>
    );
  }
}
export default HeroSection;
