import React from "react";
import HeroSection from "./layouts/hero-section/hero-section";
import ClientsSection from "./layouts/clients-section/clients-section";

function App() {
  return (
    <div className="App">
      <div id="hero-section">
        <HeroSection />
      </div>
      <ClientsSection />
    </div>
  );
}

export default App;
